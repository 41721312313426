import { useSignal } from "@preact/signals";
import { useEffect } from "preact/hooks";
import TestSuite from "./TestSuite.tsx";
import HeaderStats from "../components/HeaderStats.tsx";

interface Step {
  request: {
    method: string;
    url: string;
    headers: { [key: string]: string };
    body: string;
  };
  response: {
    status: number;
    statusText: string;
    headers: { [key: string]: string };
    body: string;
  };
  assertions: {
    type: string;
    expected: string | number | boolean | object | null;
    actual: string | number | boolean | object | null;
    passed: boolean;
    message: string;
    line: number;
  }[];
  duration: number;
}

interface Test {
  name: string;
  steps: Step[];
  result: string;
  sourceCode: string;
  lineOffset: number;
  duration: number;
}

interface TestSuiteData {
  name: string;
  tests: Test[];
  duration: number;
}

interface DataDisplayProps {
  defaultFile: string;
  defaultUrlMap?: string; // Make defaultUrlMap optional
}

export default function DataDisplay({ defaultFile, defaultUrlMap }: DataDisplayProps) {
  const data = useSignal<TestSuiteData[]>([]);
  const file = useSignal(defaultFile);
  const urlMap = useSignal(defaultUrlMap || ""); // Initialize with empty string if defaultUrlMap is not provided

  const showPassed = useSignal(true);
  const error = useSignal<string | null>(null); // State to track errors
  const loading = useSignal(true); // State to track loading

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const fileParam = params.get("file") || defaultFile;
    const urlMapParam = params.get("urlMap") || defaultUrlMap || ""; // Handle missing defaultUrlMap

    // Get current origin dynamically (works for both dev and production)
    const origin = window.location.origin;

    // Use relative URLs if fileParam or urlMapParam is not a full URL
    file.value = fileParam.startsWith("http")
      ? fileParam
      : `${origin}/api/data?file=${encodeURIComponent(fileParam)}`;

    urlMap.value = urlMapParam.startsWith("http")
      ? urlMapParam
      : urlMapParam ? `${origin}/api/data?file=${encodeURIComponent(urlMapParam)}` : ""; // Handle missing URL map

    // Fetch test data
    loading.value = true;
    fetch(file.value)
      .then((response) => {
        if (!response.ok) throw new Error(`Failed to load file: ${response.status}`);
        return response.json();
      })
      .then((fetchedData: TestSuiteData[]) => {
        data.value = fetchedData;
        error.value = null; // Clear any previous error
      })
      .catch((err) => {
        error.value = err.message; // Capture the error message for file loading errors
      })
      .finally(() => {
        loading.value = false; // Stop loading after data is fetched or error occurs
      });

    // Fetch URL map data if urlMap is provided
    if (urlMap.value) {
      fetch(urlMap.value)
        .then((response) => {
          if (!response.ok) {
            // Silently fail if the URL map cannot be loaded
            return;
          }
          return response.json();
        })
        .then((fetchedUrlMap) => {
          if (fetchedUrlMap) {
            console.log("Fetched URL map data:", fetchedUrlMap); // Handle this data as needed
          }
        })
        .catch((_err) => {
          // Silently handle errors for URL map without logging or affecting the UI
        });
    }
  }, [file.value, urlMap.value]);

  // Calculate total passed and failed tests
  const totalPassed = data.value.reduce((sum, suite) => {
    return sum + suite.tests.filter((test) => test.result.toLowerCase() === "pass").length;
  }, 0);

  const totalFailed = data.value.reduce((sum, suite) => {
    return sum + suite.tests.filter((test) => test.result.toLowerCase() === "fail").length;
  }, 0);

 
  // Gesamtdauer der Tests
  const totalDuration = (data.value.reduce((sum, suite) => sum + suite.duration, 0))/ 1000;
  
   // Get total duration from the last entry
  //const totalDuration = data.value.length > 0 ? data.value[data.value.length - 1].duration : 0;

  // Toggle visibility of passed tests
  const togglePassVisibility = () => {
    showPassed.value = !showPassed.value;
  };

  

  if (error.value) {
    return (
      <div class="p-4 text-red-600 bg-red-100 rounded">
        <strong>Error:</strong> {error.value}
      </div>
    );
  }

  return (
    <div class="p-2 sm:p-3">
      <HeaderStats
        totalPassed={totalPassed}
        totalFailed={totalFailed}
        totalDuration={totalDuration}
        togglePassVisibility={togglePassVisibility}
        showPassed={showPassed.value}
      />
      
      {data.value.map((suite) => (
        <TestSuite key={suite.name} {...suite} showPassed={showPassed.value} urlMap={urlMap.value || undefined} />
      ))}
    </div>
  );
}
