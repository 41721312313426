interface HeaderStatsProps {
  totalPassed: number;
  totalFailed: number;
  totalDuration: number;
  togglePassVisibility: () => void; // Function to toggle the visibility of "pass" tests
  showPassed: boolean; // Determines whether "pass" tests should be shown
}

export default function HeaderStats({
  totalPassed,
  totalFailed,
  totalDuration,
  togglePassVisibility,
  showPassed,
}: HeaderStatsProps) {
  return (
    <div class="bg-slate-200 dark:bg-gray-800 p-2 shadow-lg mb-6 gap-2">
      <h1 class="text-sm font-bold mb-2 text-gray-800 dark:text-gray-100 sm:text-base md:text-lg">
        Test Statistics
      </h1>
  
      <div class="flex flex-wrap items-center space-x-2 sm:space-x-4 gap-1">
        {/* Display passed tests with a strike-through effect when showPassed is false */}
        <p
          class={`text-green-600 dark:text-green-400 text-xs sm:text-sm md:text-base font-semibold relative ${
            !showPassed ? "line-through-thick" : ""
          }`}
        >
          ✅ Passed: {totalPassed}
        </p>
  
        {/* Toggle button to filter out passed tests */}
        <p class="flex items-center">
          <button
            class={`focus:outline-none px-2 py-1 rounded-xl transition-all transform text-xs sm:text-sm md:text-base font-semibold ${
              !showPassed
                ? 'bg-red-400 text-white hover:opacity-80' // Active state: Red background when hiding passed tests
                : 'bg-red-50 text-red-600 dark:bg-red-700 dark:text-white hover:opacity-80'
            } hover:shadow-md hover:scale-105`}
            onClick={togglePassVisibility}
          >
            ❌ Failed: {totalFailed}
          </button>
        </p>
  
        <p class="text-gray-600 dark:text-gray-400 text-xs sm:text-sm md:text-base font-semibold">
          FHIR Compliance: {(totalPassed / (totalPassed + totalFailed) * 100).toFixed(1)} %
        </p>
  
        {/* Optional: Display total test duration */}
        {/* 
        <p class="text-blue-600 dark:text-blue-400 text-xs sm:text-sm md:text-base font-semibold">
          🕒 Total Duration: {totalDuration.toFixed(1)} s
        </p>
        */}
      </div>
    </div>
  );
  
}
